import { createHash, randomBytes } from 'node:crypto'

// How do we use it on browser?
// https://remix.run/docs/en/main/file-conventions/remix-config#browsernodebuiltinspolyfill
// O remix faz o polyfill para o browser via JSPM e esbuild-plugins-node-modules-polyfill
// https://github.com/jspm/jspm-core/tree/main/src-browser
// https://github.com/imranbarbhuiya/esbuild-plugins-node-modules-polyfill

// https://github.com/jspm/jspm-core/blob/c0a60d66c00f23811c1ab79df2dc0bdf9e43fb49/src-browser/crypto.js#L3
const generateUniqueData = () =>
  `${Date.now().toString()}-${randomBytes(16).toString('hex')}`

// https://github.com/jspm/jspm-core/blob/c0a60d66c00f23811c1ab79df2dc0bdf9e43fb49/src-browser/crypto.js#L41C12-L41C22
export const hashDataStringMD5 = (data?: string) =>
  createHash('md5')
    .update(data ?? generateUniqueData())
    .digest('hex')
